'use strict';

var focusHelper = require('../components/focus');
var productJqueryHelper = require('./productJqueryHelper');

module.exports = {
    attributeSelect: productJqueryHelper.attributeSelect,
    methods: {
        editBonusProducts: function (data) {
            productJqueryHelper.chooseBonusProducts(data);
        },
        miniCartReportingUrl: productJqueryHelper.miniCartReportingUrl,
        handlePostCartAdd: productJqueryHelper.handlePostCartAdd,
        lockbodyscroll: productJqueryHelper.lockbodyscroll,
        clearbodyscroll: productJqueryHelper.clearbodyscroll
    },
    focusChooseBonusProductModal: productJqueryHelper.focusChooseBonusProductModal,

    onClosingChooseBonusProductModal: function () {
        $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'false');
        });
    },

    trapChooseBonusProductModalFocus: function () {
        $('body').on('keydown', '#chooseBonusProductModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#chooseBonusProductModal',
                firstElementSelector: '.close',
                lastElementSelector: '.add-bonus-products'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },
    colorAttribute: function () {
        $(document).on('click', '[data-attr="color"] button', function (e) {

            e.preventDefault();
            
            if ($(this).find(".overlay").is(':visible')) {
                $(this).closest('.color-attributes').removeClass('collapsed');
                return;
            }

            if ($(this).attr('disabled') || $(this).find('.selected').length > 0) {
                return;
            }
            var isModalContext = $(this).closest('#storesProductInventoryModal').length > 0 ? true : false;
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }

            productJqueryHelper.attributeSelect($(this).attr('data-url'), $productContainer, isModalContext);
        });
    },

    generalAttribute: function () {
        $(document).on('click', '.general-attributes > button', function (e) {
            e.preventDefault();

            if ($(this).attr('disabled') || $(this).find('.selected').length > 0) {
                return;
            }
            
            var isModalContext = $(this).closest('#storesProductInventoryModal').length > 0 ? true : false;
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }

            productJqueryHelper.attributeSelect($(this).attr('data-url'), $productContainer, isModalContext);
        });
    },

    selectAttribute: function () {
        $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
            e.preventDefault();

            var isModalContext = $(this).closest('#storesProductInventoryModal').length > 0 ? true : false;
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            productJqueryHelper.attributeSelect(e.currentTarget.value, $productContainer, isModalContext);
        });

        if($('.attribute-dropdown .dropdown-item').hasClass('selected')) {
            $('.attribute-dropdown .dropdown-toggle').html($('.attribute-dropdown .dropdown-item.selected').html());
        }

        $(document).on('click', '.attribute-dropdown .dropdown-item', function(e) {
            e.preventDefault();

            if($(this).hasClass('selected')){
            	return;
            }
            
            $(this).parents('.attribute-dropdown').find('.dropdown-toggle').html($(this).html());
            $(this).parents('.attribute-dropdown').find('.dropdown-toggle').trigger('click');
            var isModalContext = $(this).closest('#storesProductInventoryModal').length > 0 ? true : false;
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            productJqueryHelper.attributeSelect(e.currentTarget.href, $productContainer, isModalContext);
        });
    },

    instorePickup: function(){
    	$(document).on('click', '.instore-pickup', function (e) {
    		e.preventDefault();
    		var url = $(this).attr("href");
    		$.get(url, function (data) {
                $('#storesProductInventoryModal').remove();
                $(data).appendTo('body');
                $(document).off('click', 'button.instore-pickup-showopeninghours');
                $(document).on('click', 'button.instore-pickup-showopeninghours', function(){
                	var storedetailsContainer = $(this).closest('.instorepickup-storedetails');
                	var openingHoursContainer = storedetailsContainer.find('.stores-openinghours');
                	if(openingHoursContainer.attr('hidden')){
                		openingHoursContainer.removeAttr('hidden');
                	}else{
                		openingHoursContainer.attr('hidden', '');
                	}
                });
                $(document).off('click', '.instore-pickup-close');
                $(document).on('click', '.instore-pickup-close', function(){
                    $('#storesProductInventoryModal').modal('hide');
                    productJqueryHelper.clearbodyscroll();
                });
                $(document).off('click', '#storesProductInventoryModal .modal-header .close');
                $(document).on('click', '#storesProductInventoryModal .modal-header .close', function(){
                    productJqueryHelper.clearbodyscroll();
                });
                if($('#storesProductInventoryModal .attribute-dropdown .dropdown-item').hasClass('selected')) {
                    $('#storesProductInventoryModal .attribute-dropdown .dropdown-toggle').html(
                        $('#storesProductInventoryModal .attribute-dropdown .dropdown-item.selected').html()
                    );
                }
                $("#storesProductInventoryModal").modal("show");
                productJqueryHelper.lockbodyscroll(document.querySelector('#storesProductInventoryModal .modal-body'));
                $("#storesProductInventoryModal").on('hide.bs.modal', function(e) {
                    productJqueryHelper.clearbodyscroll();
                });
            });
    	});
    },
    
    sizeGuide: function(){
    	$(document).on('click', '.size-guide', function (e) {
    		$('#sizeChartModal').modal('show');
    	});
    },
    
    storeInventory: function() {
    	$(document).on('click', '.store-inventory', function (e) {
            e.preventDefault();
            productJqueryHelper.getStores($(this).data('findstores'));
    	});
    },

    availability: productJqueryHelper.availability,
    owlCarousel: productJqueryHelper.owlCarousel,

    addToCart: productJqueryHelper.addToCart,


    selectBonusProduct: function () {
        $(document).on('click', '.select-bonus-product', function () {
            var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
            var pid = $(this).data('pid');
            var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
            var submittedQty = parseInt($choiceOfBonusProduct.find('.bonus-quantity-select').val(), 10);
            var totalQty = 0;
            $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
                totalQty += $(this).data('qty');
            });
            totalQty += submittedQty;
            var optionID = $choiceOfBonusProduct.find('.product-option').data('option-id');
            var valueId = $choiceOfBonusProduct.find('.options-select option:selected').data('valueId');
            if (totalQty <= maxPids) {
                var selectedBonusProductHtml = ''
                + '<div class="selected-pid row" '
                + 'data-pid="' + pid + '"'
                + 'data-qty="' + submittedQty + '"'
                + 'data-optionID="' + (optionID || '') + '"'
                + 'data-option-selected-value="' + (valueId || '') + '"'
                + '>'
                + '<div class="col-sm-11 col-9 bonus-product-name" >'
                + $choiceOfBonusProduct.find('.product-name').html()
                + '</div>'
                + '<div class="col-1"><i class="fa fa-times" aria-hidden="true"></i></div>'
                + '</div>'
                ;
                $('#chooseBonusProductModal .selected-bonus-products').append(selectedBonusProductHtml);
                $('.pre-cart-products').html(totalQty);
                $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
            } else {
                $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
            }
        });
    },
    removeBonusProduct: function () {
        $(document).on('click', '.selected-pid', function () {
            $(this).remove();
            var $selected = $('#chooseBonusProductModal .selected-bonus-products .selected-pid');
            var count = 0;
            if ($selected.length) {
                $selected.each(function () {
                    count += parseInt($(this).data('qty'), 10);
                });
            }

            $('.pre-cart-products').html(count);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
        });
    },
    enableBonusProductSelection: function () {
        $('body').on('bonusproduct:updateSelectButton', function (e, response) {
            $('button.select-bonus-product', response.$productContainer).attr('disabled',
                (!response.product.readyToOrder || !response.product.available));
            var pid = response.product.id;
            $('button.select-bonus-product', response.$productContainer).data('pid', pid);
        });
    },
    showMoreBonusProducts: function () {
        $(document).on('click', '.show-more-bonus-products', function () {
            var url = $(this).data('url');
            $('.modal-content').spinner().start();
            $.ajax({
                url: url,
                method: 'GET',
                success: function (html) {
                    var parsedHtml = productJqueryHelper.parseHtml(html);
                    $('.modal-body').append(parsedHtml.body);
                    $('.show-more-bonus-products:first').remove();
                    $('.modal-content').spinner().stop();
                },
                error: function () {
                    $('.modal-content').spinner().stop();
                }
            });
        });
    },
    addBonusProductsToCart: function () {
        $(document).on('click', '.add-bonus-products', function () {
            var $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
            var queryString = '?pids=';
            var url = $('.choose-bonus-product-dialog').data('addtocarturl');
            var pidsObject = {
                bonusProducts: []
            };

            $.each($readyToOrderBonusProducts, function () {
                var qtyOption =
                    parseInt($(this)
                        .data('qty'), 10);

                var option = null;
                if (qtyOption > 0) {
                    if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                        option = {};
                        option.optionId = $(this).data('optionid');
                        option.productId = $(this).data('pid');
                        option.selectedValueId = $(this).data('option-selected-value');
                    }
                    pidsObject.bonusProducts.push({
                        pid: $(this).data('pid'),
                        qty: qtyOption,
                        options: [option]
                    });
                    pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
                }
            });
            queryString += JSON.stringify(pidsObject);
            queryString = queryString + '&uuid=' + $('.choose-bonus-product-dialog').data('uuid');
            queryString = queryString + '&pliuuid=' + $('.choose-bonus-product-dialog').data('pliuuid');
            $.spinner().start();
            $.ajax({
                url: url + queryString,
                method: 'POST',
                success: function (data) {
                    $.spinner().stop();
                    if (data.error) {
                        $('#chooseBonusProductModal').modal('hide');
                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append('<div class="add-to-cart-messages"></div>');
                        }
                        $('.add-to-cart-messages').append(
                            '<div class="alert alert-danger add-to-basket-alert text-center"'
                            + ' role="alert">'
                            + data.errorMessage + '</div>'
                        );
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                        }, 3000);
                    } else {
                        $('.configure-bonus-product-attributes').html(data);
                        $('.bonus-products-step2').removeClass('hidden-xl-down');
                        $('#chooseBonusProductModal').modal('hide');

                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append('<div class="add-to-cart-messages"></div>');
                        }
                        $('.minicart-quantity').html(data.totalQty);
                        $('.add-to-cart-messages').append(
                            '<div class="alert alert-success add-to-basket-alert text-center"'
                            + ' role="alert">'
                            + data.msgSuccess + '</div>'
                        );
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                            if ($('.cart-page').length) {
                                location.reload();
                            }
                        }, 1500);
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    quantityChange: productJqueryHelper.quantityChange,

    quantityChangeProductCard: function () {
    	$('body').on('click', '.quantity-up-productcard', function (e) {
            e.preventDefault();
            
            var currentQuantity = $(this).closest('.quantity-spinner').find('.quantity-select').val();

            currentQuantity = currentQuantity.replace(',','').replace('.','');

            if(currentQuantity == Number(999)){
                return;
            }
            
        	var newQuantity = Number(currentQuantity) +1;
            
            $(this).closest('.quantity-spinner').find('.quantity-select').val(Number(newQuantity));
            
            $(this).closest('.quantity-spinner').find('.quantity-select').trigger('change');
            
            
      
        });
    	
    	$('body').on('click', '.quantity-down-productcard', function (e) {
            e.preventDefault();
            
            var currentQuantity = $(this).closest('.quantity-spinner').find('.quantity-select').val();
            
            if(currentQuantity == 1){
            	$(this).closest('.product-card-footer').find('.remove-product').trigger('click');
            }else{
            	$(this).closest('.quantity-spinner').find('.quantity-select').val(currentQuantity -1);
            	$(this).closest('.quantity-spinner').find('.quantity-select').trigger('change');
            }
      
        });
    },
    
    getPidValue: productJqueryHelper.getPidValue,
    getQuantitySelected: productJqueryHelper.getQuantitySelected,
    miniCartReportingUrl: productJqueryHelper.miniCartReportingUrl
};
